/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */

function CampaignContent({ created, name }) {
  return (
    created
      ? (
        <p className="text-center">
            Campaign has been created.
        </p>
      ) : (
        <p className="text-center">
            Campaign has been successfully saved. You need to re-run all booking.
        </p>
      )
  );
}

export default CampaignContent;
