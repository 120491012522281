/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable no-useless-return */
/* eslint-disable import/no-cycle */
/* eslint-disable react/react-in-jsx-scope */
import React, {
 useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Formik, Form } from 'formik';
import '../campaigns.css';
import {capitalize, isEmpty, range} from 'lodash';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import InputLabel from '../../Forms/InputLabel';
import DatePicker from "react-datepicker";
import '../../../assets/css/custom-date-picker.css';
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import moment from "moment";

import {
  op2miseOptions,
  initialFields,
  formatDate,
  transformWeeklySpendRange,
  transformAudiences,
  checkAudienceValue,
  isIntKey,
  replaceWithInt,
  trimStringWithWhitespace,
  allow1Decimal, formatDecimal, transformWeeklySpends, allow2Decimals,
} from '../helpers/helpers';
import Switch from '../../Forms/Switch';
import Button from '../../../shared/components/button/Button';
import useStore from '../hooks/useCampaignStore';
import { updateCampaign, addCampaign } from '../requests';
import useTabSelector from '../hooks/useTabSelector';
import useWeeklySpend, { getTotalWeeks } from '../hooks/useWeeklySpend';
import {
  campaignHeaderFormSchema,
} from '../helpers/formSchema';
import Dialog from '../../Dialogs/Dialog';
import CampaignContent from '../Dialog/CampaignContent';
import UnsaveFormWarning from '../Dialog/UnsaveFormWarning';
import useHandleUnsavedForm from '../hooks/useHandleUnsavedForm';
import { fetchDefaultDateById, preSubmitForm } from '../helpers/form';
import useSidenavStore from '../../../shared/sidebar/hooks/useSidenavStore';
import useDirtyForm from '../hooks/useDirtyForm';
import SelectComponent from '../../../shared/elements/select/select.component';
import { useCallbackPrompt } from 'components/_reusable/blocker/UsePromptComponent.ts';
import ConfirmLeaveModal from 'shared/components/confirm-leave-modal/ConfirmLeaveModal';

const CampaignHeaderForm = forwardRef(({}, ref) => {
  const {
    setShowCampaignForms,
    isFormTouched,
    setIsFormTouched,
    selectedCampaign,
    changeFormTab,
    setSelectedCampaign,
    setActiveFormId,
    setIsFormSaved,
    isFormSaved,
    setChangeFormTab,
    markets,
    setMarkets,
    setWeeklySpendDates,
    dateFormat,
    setDateFormat,
    setCurrencyCode,
    newCampaign,
    setNewCampaign,
    fields,
    setFields,
    weeklySpendDates,
  } = useStore((state) => state);
  const navigate = useNavigate();
  const { toPath } = useSidenavStore((state) => state);
  const previousAudiences = useRef(markets.audiences);

  const [switchOpt, setSwitchOpt] = useState(op2miseOptions);
  const [showDialog, setShowDialog] = useState(false);
  const [created, setCreated] = useState(true);
  const [proceedAndSave, setProceedAndSave] = useState(false);
  const [defaultWeekly, setdefaultWeekly] = useState(false);
  const [defaultMarket, setdefaultMarket] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [audienceList, setAudienceList] = useState([]);

  // Dirty forms
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    isFormTouched
  );
  const [shouldExecuteSaveAndProceed, setShouldExecuteSaveAndProceed] = useState([false, () => {}]);

  useImperativeHandle(ref, () => ({
    submitForm(callbackFunction) {
      setShouldExecuteSaveAndProceed([true, callbackFunction]);
    },
  }));

  useWeeklySpend(fields.startDate, fields.endDate);

  useHandleUnsavedForm(setShowDialog);

  const {
    handleProceed, handleCancel, handleDiscard, gotoNav,
  } = useDirtyForm({
    setShowDialog,
    setProceedAndSave,
  });

  useEffect(() => {
    setFields(initialFields);
    if (markets.audiences) {
      setAudienceList(transformAudiences(markets.audiences));
    }
    if (JSON.stringify(markets.audiences) !== JSON.stringify(previousAudiences.current)) {
      setSwitchOpt(op2miseOptions);
      previousAudiences.current = markets.audiences;
    }
  }, [markets.audiences]);

  useEffect(() => {
    if (selectedCampaign) {
      setFields({
        ...selectedCampaign,
        startDate: selectedCampaign.startDate,
        endDate: selectedCampaign.endDate,
        objective: {
          ...op2miseOptions[selectedCampaign.objective],
          active: true,
        },
        status: ['A', 'a', 'N', 'n', 'D', 'd', 'F', 'f', true].some((s) => s === selectedCampaign.status),
        audience: selectedCampaign.audience ?? 'Please select',
      });
      setAudienceList(transformAudiences(selectedCampaign.audiences));
      setSwitchOpt(
          op2miseOptions.map((opt, key) => ({
            ...opt,
            active: key === selectedCampaign.objective,
          })),
      );
    }
  }, [selectedCampaign]);

  useTabSelector(fields.startDate, fields.endDate, fields.budget);

  const handleNavigate = (proceed) => {
    if (!showConfirmLeaveModal && !shouldExecuteSaveAndProceed[0]) confirmNavigation();
    else {
      if (shouldExecuteSaveAndProceed[0]) {
        shouldExecuteSaveAndProceed[1]();
        setShouldExecuteSaveAndProceed([false, () => {}]);
      } else {
        setShowConfirmLeaveModal(false);
        if (proceed) handleClose(true);
      }
    }
  };

  const handleSwitch = (_, option) => {
    setSwitchOpt(
        switchOpt.map((opt) => ({
          ...opt,
          active: opt.label === option.label,
        })),
    );
    setFields({ ...fields, objective: { ...option, active: true } });
    setIsFormTouched(true);
  };

  const onSubmit = async (
      {
        initial, status, audiences, ...values
      },
      { setErrors },
  ) => {
    const audienceRes = checkAudienceValue(fields.audience);
    if (audienceRes.audience) {
      setErrors(audienceRes);
      return;
    }

    const incrementStartDate = new Date(values.startDate);
    const incrementEndDate = new Date(values.endDate);
    incrementStartDate.setDate(incrementStartDate.getDate() + 1);
    incrementEndDate.setDate(incrementEndDate.getDate() + 1);

    let fieldsCopy = {
      ...values,
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      objective: op2miseOptions.findIndex(
          (opt) => opt.label === fields.objective.label,
      ),
      reach: parseFloat(values.reach).toFixed(2),
      frequency: parseFloat(values.frequency).toFixed(1),
      costPerPoint: parseInt(values.costPerPoint),
      budget: parseInt(values.budget),
      noWeeks: getTotalWeeks(values.startDate, values.endDate),
      toggleStatus: status,
      resetDefaultMarket: defaultMarket,
      resetDefaultWeekly: defaultWeekly,
      audienceId: audienceList.find(({ name }) => name === fields.audience).id,
      audience: fields.audience,
    };

    if (selectedCampaign) setCreated(false);
    setIsFormSaved(true);
    setIsCreate(!selectedCampaign);
    const result = (await selectedCampaign)
        ? updateCampaign(fieldsCopy, fields.id)
        : addCampaign(fieldsCopy);
    result
        .then((res) => {
          if (!res.id) {
            return;
          }
          const {
            id,
            marketDistributionResults,
            weeklyDistributionResults,
            startDate,
          } = res;
          fieldsCopy = {
            ...fieldsCopy,
            startDate: values.startDate,
            endDate: values.endDate,
          }
          setSelectedCampaign({
            ...fieldsCopy,
            status,
            audiences: audienceList,
            ...(selectedCampaign ? {} : { id }),
          });
          setMarkets(marketDistributionResults);
          setWeeklySpendDates(
              transformWeeklySpends(weeklyDistributionResults),
          );

          setCurrencyCode(res.currencyCode);
          setDateFormat(res.dateformat);
          setIsFormSaved(true);
          setShowDialog(true);
          setIsFormTouched(false);
          handleNavigate(true);
          if (showPrompt) setNewCampaign(false);
        })
        .catch(() => {
          setShowDialog(false);
          handleNavigate(false);
        });

    setIsFormSaved(true);
    setChangeFormTab(newCampaign);
  };

  const handleClose = (proceed) => {
    if (isFormTouched && !proceed) {
      setShowConfirmLeaveModal(true);
    } else {
      setShowCampaignForms(false);
      setNewCampaign(false);
      setIsFormTouched(false);
      setShowConfirmLeaveModal(false);
    }
  }

  const isNotTouched = (errors, touched) => {
    if (
        isEmpty(errors)
        && isEmpty(touched)
        && !isFormSaved
        && !fields.initial
    ) {
      setIsFormSaved(true);
    }
  };

  const handleCloseDialog = () => {
    if (isCreate) setActiveFormId('marketSpend');
    setShowDialog(false);
    setIsFormTouched(false);
    if (proceedAndSave) navigate(toPath);
    gotoNav();
  };

  const handleSelectAudience = (val) => {
    setFields({
      ...fields,
    audience: val.name,
    });
    setIsFormTouched(true);
};

  return <div className="d-flex w-100 gap-3 default-text" key={fields.startDate}>
    <Formik
        initialValues={fields}
        validationSchema={campaignHeaderFormSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
    >
      {({
          values,
          handleChange: onChange,
          errors,
          touched,
          setTouched,
          submitForm,
          validateForm,
          setErrors,
          setFieldValue,
        }) => {
        const [startDate, setStartDate] = useState(null);
        const [startDateError, setStartDateError] = useState(null);
        const [endDate, setEndDate] = useState(null);
        const [endDateError, setEndDateError] = useState(null);
        const years = range(1990, 2200);
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        if (isEmpty(touched)) isNotTouched(errors, touched);
        const handleChange = (event) => {
          const e = { ...event };
          let val = e.target.value;
          const numericOnly = ['budget', 'costPerPoint'];
          const decimalOnly = ['reach', 'frequency'];

          val = val.replace(/^0+/, '');

          if (val === '.00' && e.target.name === 'reach')  {
            return
          }

          if (val === '.0' && e.target.name === 'frequency')  {
            return
          }

          if (numericOnly.includes(e.target.name) && val.includes('.')) {
            e.target.value = val.replace('.', '0');
            return
          }


          if (e.nativeEvent.inputType === 'insertFromPaste') {
            if (numericOnly.includes(e.target.name)) {
              // eslint-disable-next-line no-use-before-define
              e.target.value = handleTrimNumeric(e.target.value);
            }
          } else if (e.nativeEvent.inputType === 'insertText') {
            if (numericOnly.includes(e.target.name)) {
              e.target.value = val.replace(/[^0-9]/g, '');
            }
          }

          if (decimalOnly.includes(e.target.name)) {
            const maxValue = 100;

            if (val > maxValue) return;
            val = val.replace('..', '.');
          }

          if (numericOnly.includes(e.target.name) || decimalOnly.includes(e.target.name)) {
            e.target.value = val.replace(/^00+/, 0);
          }

          if (val.includes('.') && decimalOnly.includes(e.target.name))  {
            const valSplit = val.split('.');
            if (valSplit[0] === '') e.target.value = `0${val}`;
          }

          setTouched({ ...touched, [e.target.name]: e.target.value });
          setIsFormSaved(true);
          if (e.target.name === 'startDate' || e.target.name === 'endDate') {
            setdefaultWeekly(true);
            setdefaultMarket(true);
          }

          if (e.target.name === 'budget') {
            setdefaultMarket(true);
          }


          if (e.target.name === 'status' && !selectedCampaign) return;
          setIsFormSaved(true);

          if (isIntKey(e.target.name)) {
            e.target.value = replaceWithInt(e.target.value);
          }

          onChange(e);
          setIsFormTouched(true);
        };

        const handleTrimNumeric = (val) => {
          if (val.includes('.')) {
            // eslint-disable-next-line no-param-reassign,prefer-destructuring
            val = val.split('.')[0];
          }
          return replaceWithInt(val.replace(/\D/g, ''));
        };

        fetchDefaultDateById('startDate', dateFormat);
        fetchDefaultDateById('endDate', dateFormat);

        const handleDecimal = (e) => {
          const val = e.target.value;
          e.target.value = replaceWithInt(allow1Decimal(e, val));

          handleChange(e);
        };

        const handleReachInput = (e) => {
          const val = e.target.value;
          e.target.value = replaceWithInt(allow2Decimals(e, val));
          handleChange(e);
        }

        const handleOnValidate = (proceedFromDirtyForm) => {
            setStartDateError(startDate == null ? 'Start date is a required field': null);
            setEndDateError(endDate == null ? 'End date is a required field': null);

            if (startDate != null && endDate != null) {
              setStartDateError(startDate >= endDate ? 'Start date should not be after or equal to end date': null);
              setEndDateError(startDate >= endDate ? 'End date should not be before or equal to start date': null);
            }

          preSubmitForm({
            setErrors,
            validateForm,
            setChangeFormTab,
            setProceedAndSave,
            setIsFormSaved,
            showConfirmLeaveModal,
            setShowConfirmLeaveModal,
            cancelNavigation,
            proceedFromDirtyForm,
            submitForm,
          });
        };

        const handleOnHide = () => {
          if (showConfirmLeaveModal) setShowConfirmLeaveModal(false);
          else cancelNavigation();
        };

        const handleOnDiscard = () => {
          if (showConfirmLeaveModal) handleClose(true);
          else {
            setNewCampaign(false);
            confirmNavigation();
          }
          setIsFormTouched(false);
        };

        const handleOnSaveAndProceed = () => {
          handleOnValidate(true);
        };

        useEffect(() => {
          if (shouldExecuteSaveAndProceed[0]) handleOnSaveAndProceed();
        }, [shouldExecuteSaveAndProceed]);

        const handleStartDate = (date) => {
          setStartDate(date);
          setTouched({ ...touched, startDate: date });
          setFieldValue(
              'startDate',
              date
          );
          setdefaultWeekly(true);
          setdefaultMarket(true);
          setIsFormSaved(true);
          setIsFormTouched(true);
        };

        const handleEndDate = (date) => {
          setEndDate(date);
          setTouched({ ...touched, endDate: date });
          setFieldValue(
              'endDate',
              date
          );
          setdefaultWeekly(true);
          setdefaultMarket(true);
          setIsFormSaved(true);
          setIsFormTouched(true);
        };

        useEffect(() => {
          if (values.startDate) {
            setStartDate(new Date(values.startDate))
            setEndDate(new Date(values.endDate))
          }
        }, []);

        return <Form
                className="w-100 d-flex flex-column gap-2 form"
                key={values}
                noValidate
            >
              <Dialog
                  show={showDialog}
                  onClose={handleCloseDialog}
                  Content={
                    changeFormTab && !isFormSaved ? (
                        <UnsaveFormWarning
                            handleProceed={() => handleProceed(submitForm, validateForm, setErrors)}
                            onCancel={handleCancel}
                            handleDiscard={handleDiscard}
                        />
                    ) : null
                  }
                  isDirtyForm={changeFormTab && !isFormSaved}
              >
                <CampaignContent created={created} name={fields.name} />
              </Dialog>
              <div className="min-max-h-20 d-flex justify-content-center campagin-form customScroll">
                <div className="d-flex flex-column gap-10" style={{ width: '95%' }}>
                  <div className="d-flex gap-2 align-items-center">
                    <InputLabel
                        maxLength={30}
                        errors={errors}
                        onBlur={(e) => {
                          e.target.value = e.target.value.replace(/\s+/g, ' ').trim();
                          if (typeof onChange === 'function') onChange(e);
                          if (typeof onBlur === 'function') onBlur();

                          setFieldValue(
                              'name',
                              e.target.value
                          );
                        }}
                        label="Campaign Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Enter the Campaign name"
                        required
                    />
                    {selectedCampaign && (
                        <div
                            className={clsx({
                              'gap-2 align-items-center form-check form-switch switchInput': true,
                              'pt-0': !!errors?.name,
                            })}
                        >
                          <input
                              value={values.status}
                              onChange={handleChange}
                              name="status"
                              className={clsx({
                                'switchBtn form-check-input': true,
                                'switchBtn-off': !values.status,
                              })}
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              checked={values.status}
                          />
                          <label className="form-check-label switchLabel" style={{paddingLeft: 5}}>
                            Active
                          </label>
                        </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <span className="input-label">Start Date</span>
                      <span className="input-required">*</span>
                      <div className="d-flex flex-column" style={{ paddingTop: 5 }}>
                        <DatePicker
                            className={'input p-2 rounded'}
                            style={{ height: '10px', width: '100%', paddingTop: 10}}
                            isClearable
                            closeOnScroll={true}
                            selected={startDate ? startDate : null}
                            customInput={
                              <MaskedInput
                                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                  guide={false}
                                  pipe={dateFormat}
                                  keepCharPositions= {true}
                              />}
                            onChange={handleStartDate}
                            dateFormat={dateFormat.toLowerCase().replace('mm', "MM")}
                            placeholderText={dateFormat}
                            minDate={new Date('01/01/1900')}
                            maxDate={new Date('01/01/2200')}
                            todayButton="Today"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            showPopperArrow={false}
                            fixedHeight
                        />
                        {startDateError &&
                            <p className="is-danger capitalize">{capitalize(startDateError.replace(/([A-Z])/g, ' $1').trim())}</p>
                        }
                      </div>
                    </div>
                    <div className="col-md-4">
                      <span className="input-label">End Date</span>
                      <span className="input-required">*</span>
                      <div className="d-flex flex-column" style={{ paddingTop: 5 }}>
                        <DatePicker
                            className={'input p-2 rounded'}
                            isClearable
                            closeOnScroll={true}
                            selected={endDate ? endDate : null}
                            customInput={
                              <MaskedInput
                                  pipe={dateFormat}
                                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                  keepCharPositions= {true}
                                  guide = {false  }
                              />}

                            onChange={handleEndDate}
                            dateFormat={dateFormat.toLowerCase().replace('mm', "MM")}
                            placeholderText={dateFormat}
                            minDate={new Date('01/01/1900')}
                            maxDate={new Date('01/01/2200')}
                            todayButton="Today"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            showPopperArrow={false}
                            fixedHeight
                        />
                        {endDateError &&
                            <p className="is-danger capitalize">{capitalize(endDateError.replace(/([A-Z])/g, ' $1').trim())}</p>
                        }
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                          className="d-flex flex-column w-100 gap-5"
                          key={audienceList}
                      >
                    <span className="input-label">
                      Audience
                      <span className="input-required">*</span>
                    </span>
                        <SelectComponent
                            text={fields.audience}
                            list={audienceList}
                            onSelect={handleSelectAudience}
                            width="100%"
                            height="250px"
                            withSearch
                        />
                        {errors.audience && (
                            <p className="is-danger capitalize">
                              {errors.audience}
                            </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-100 mt-2 d-flex">
                <span className="border-bottom w-100 text-18 text-black">
                  Targets
                </span>
                  </div>
                  <div className="d-flex gap-2">
                    <InputLabel
                        errors={errors}
                        maxLength={11}
                        type="text"
                        label="Budget"
                        placeholder="Enter a budget for this campaign"
                        name="budget"
                        value={formatDecimal(values.budget)}
                        onChange={handleChange}
                        required
                    />
                    <InputLabel
                        errors={errors}
                        maxLength={4}
                        label="Frequency"
                        placeholder="Enter the target frequency for this campaign"
                        name="frequency"
                        value={values.frequency}
                        onChange={handleDecimal}
                        required
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <InputLabel
                        errors={errors}
                        maxLength={5}
                        label="Reach"
                        placeholder="Enter the target reach for this campaign"
                        name="reach"
                        value={values.reach}
                        onChange={handleReachInput}
                        required
                    />
                    <InputLabel
                        errors={errors}
                        maxLength={4}
                        label="Cost per point (CPP)"
                        placeholder="Enter the target CPP for this campaign"
                        name="costPerPoint"
                        value={formatDecimal(values.costPerPoint)}
                        onChange={handleChange}
                        required
                    />
                  </div>
                  <div className="d-flex gap-3 mt-2 align-items-center">
                    <span>Op2mise for:</span>
                    <Switch options={switchOpt} onClick={handleSwitch} />
                  </div>
                </div>
              </div>
              <div className="form-btn-container align-items-center d-flex gap-3 mt-3 justify-content-end" style={{ width: '95%' }}>
            <span className="closeBtn" onClick={() => handleClose(false)} style={{ marginRight: '34px'}}>
              Close
            </span>
                <div>
                  <Button
                    text="Save"
                    type="submit"
                    onClick={() => handleOnValidate(false)}
                  />
                </div>
              </div>

              <ConfirmLeaveModal
                show={showPrompt || showConfirmLeaveModal}
                onHide={handleOnHide}
                onSave={() => handleOnSaveAndProceed(handleOnValidate)}
                onDiscard={handleOnDiscard}
              />
            </Form>;
      }}
    </Formik>
  </div>;
})

export default CampaignHeaderForm;
