/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { lowerCase, upperCase, orderBy } from 'lodash';
import CampaignHeaderForm from '../Forms/CampaignHeaderForm';
import MarketSpendForm from '../Forms/MarketSpendForm';
import WeeklySpendForm from '../Forms/WeeklySpendForm';

export const listHeaders = [
  {
    headerId: 1,
    headerName: 'name',
    filter: {
      type: 1,
      queryNames: ['nameFilter', 'nameFilterValue'],
    },
    sortable: true,
  },
  {
    headerId: 2,
    sortable: false,
    headerName: 'budget',
  },
  {
    headerId: 3,
    sortable: false,
    headerName: 'booked',
  },
  {
    headerId: 4,
    headerName: 'reach (%)',
    sortable: false,
  },
  {
    headerId: 5,
    sortable: false,
    headerName: 'booked',
  },
  {
    headerId: 6,
    sortable: false,
    headerName: 'frequency',
  },
  {
    headerId: 7,
    sortable: false,
    headerName: 'booked',
  },
  {
    headerId: 8,
    sortable: false,
    headerName: 'cpp',
  },
  {
    headerId: 9,
    sortable: false,
    headerName: 'booked',
  },

  {
    headerId: 10,
    headerName: 'start date',
    sortable: true,
  },
  {
    headerId: 11,
    headerName: 'end date',
    sortable: true,
  },
  {
    headerId: 12,
    headerName: 'weeks',
    sortable: false,
  },
  {
    sortable: true,
    headerId: 13,
    headerName: 'status ',
    filter: {
      type: 2,
      queryNames: ['statusFilterValue'],
      list: [
        { id: 1, name: 'New', value: 'N' },
        { id: 2, name: 'Avails', value: 'A' },
        { id: 3, name: 'Draft', value: 'D' },
        { id: 4, name: 'Final', value: 'F' },
        { id: 5, name: 'Inactive', value: 'I' },
      ],
    },
  },
];

export const tabs = [
  {
    id: 'campaignHeader',
    title: 'campaign header',
    Content: CampaignHeaderForm,
    active: true,
  },
  {
    id: 'marketSpend',
    title: 'market spend',
    Content: MarketSpendForm,
    active: false,
  },
  {
    id: 'weeklySpend',
    title: 'weekly spend',
    Content: WeeklySpendForm,
    active: false,
  },
];

export const op2miseOptions = [
  { label: 'budget', active: true },
  { label: 'reach', active: false },
];

export const initialFields = {
  name: '',
  startDate: '',
  endDate: '',
  budget: '',
  frequency: '',
  reach: '',
  costPerPoint: '',
  objective: op2miseOptions.find((opt) => opt.active),
  initial: true,
  status: true,
  audience: 'Please select',
};

export const getTotalPercent = (fields, key) => {
  if (!fields) return 0;
  const programValues = fields?.map((program) => {
    const value = (parseFloat(program[key]) || 0).toFixed(2);
    return parseFloat(value);
  });
  const floorNum = programValues.reduce((val1, val2) => val1 + val2);

  return Math.ceil(floorNum * 100) / 100;
};

export const formatDisplayDate = (date) => moment(date).format('MM/DD/YYYY');

export const formatDate = (date) => moment(date).format('YYYY-MM-DD');

export const formatDateUTC = (date) => moment(date).format('DD-MM-YYYY');

export const formatDateToUTC = (date) => moment(date)
  .utc()
  .format('YYYY-MM-DD');

export const formatDateFromSetup = (date, format) => {
  return moment(date).utc().format(format);
};

export const formatCurrency = (amount, currencyCode) => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return `${USDollar.format(amount)}`;
};

export const formatDecimal = (amount) => {
  let val = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return amount === '' ? '' : `${val.format(amount)}`;
};

export const formatPercent = (amount) => (amount ? `${amount} %` : '');

export const getWeekRange = (date, day = 'Monday') => {
  const start = moment(moment(date).format('DD.MM.YYYY'), 'DD.MM.YYYY');
  return start.day(day);
};

export const formatAmount = (number) => {
  return number || number === 0 ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
};

export const getStartAndEndDayOfTheWeek = (startDate) => {
  const today = moment(startDate);
  const from_date = today.startOf('week');
  const to_date = today.endOf('week');
  return {
    start: from_date.day('Monday'),
    end: to_date.add(7, 'days').day('Sunday'),
  };
};

export const transformWeeklySpendRange = (defaultWS, sDate) => {
  let start = sDate;
  const weekRange = [];
  defaultWS.forEach((dws) => {
    const startDate = getWeekRange(start, 'Sunday');
    const endDate = getWeekRange(start, 'Saturday');
    weekRange.push({
      ...dws,
      startWeek: startDate,
      endWeek: endDate,
    });
    start = endDate.add(1, 'days').format('YYYY-MM-DD');
  });
  return weekRange;
};

export const transformMarkets = (markets, campaign) => markets.map((market) => ({
  ...market,
  distribution: (market.allocation * campaign.budget) / 100,
  percentage: 100 / markets.length,
}));

export const transformWeeklySpends = (weeklySpend) => weeklySpend.map((weekly) => ({
  ...weekly,
  allocation: weekly.allocation,
}));

export const Status = {
  N: 'New',
  A: 'Avails',
  D: 'Draft',
  F: 'Final',
  I: 'Inactive',
};

export const isMatched = (key, arr) => arr.some((keyword) => keyword === key);

export const transformAudiences = (audiences) => orderBy(
  audiences?.map(({ id, name }) => ({ id, name })),
  [upperCase('name')],
  ['asc'],
);

export const checkAudienceValue = (val) => {
  if (lowerCase(val) === lowerCase(initialFields.audience) || !val) {
    return { audience: 'Please select audience' };
  }
  return {};
};

export const isIntKey = (name) => [
  'budget',
  'frequenct',
  'costPerPoint',
].some((key) => key === name);

export const replaceWithInt = (value) => value.replace(/[^0-9.]+/g, '');

export const allow1Decimal = (e, val) => {
  const regExp = new RegExp('^\\d{0,2}\\.[0-9]{1}$');

  if (
    !regExp.test(e.target.value)
      && val.length === 4
      && e.nativeEvent.inputType !== 'deleteContentBackward'
      && e.nativeEvent.inputType !== 'deleteContentForward'
  ) {
    e.target.value = val.toString().match(/^-?\d+(?:\.\d{0,1})?/)
      ? val.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]
      : 0;
  }
  return e.target.value;
};

export const allow2Decimals = (e) => {
  // regex that matches 1 or more digits, followed by an optional
  // decimal point with only up to two digits after that decimal
  const regExp = /^\d*\.?(\d{0,2})$/;
  const stringInput = e.target.value.toString();

  // check if input matches regex
  if (!regExp.test(stringInput)) {
    // get matching pattern starting at the beginning of input and only return that pattern
    const matchedVal = stringInput.match(/^\d*\.?(\d{0,2})/)[0];

    // check if matchedVal is not null
    if (matchedVal !== null) {
      e.target.value = matchedVal;
    }
  }

  // return input
  return e.target.value;
};

export const allow1Point = (val) => {
  if (val.includes('.')) {
    const valSplit = val.split('.');
    // eslint-disable-next-line no-param-reassign
    if (valSplit[0] === '') val = `0${val}`;
  }
  return val;
};

export const isWhiteSpace = (value) => value.trim().length === 0;

export const trimStringWithWhitespace = (str) => {
  const hasWhiteSpace = /\s/g.test(str);
  if (hasWhiteSpace) return str.replace(/\s{2,}/g, ' ').trim();
  return str;
};

export const scheduleStatus = {
  DRAFT: 'D',
  AVAILS: 'A',
  NOAVAILS: 'N',
};
