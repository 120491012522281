import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const AccessDeniedComponent = lazy(() => import('../components/_error-pages/access-denied/AccessDeniedComponent'));
const Campaigns = lazy(() => import('../components/campaigns'));
const Avails = lazy(() => import('../components/avails'));
const Markets = lazy(() => import('../components/markets/MarketList'));
const Programme = lazy(() => import('../components/programme'));
const OrganisationComponent = lazy(() => import('../components/organisation/OrganisationComponent'));
const HomeComponent = lazy(() => import('../components/home/HomeComponent'));
const InternalServerComponent = lazy(() => import('../components/_error-pages/internal-server/InternalServerComponent'));
const NotFoundComponent = lazy(() => import('../components/_error-pages/not-found/NotFoundComponent'));
const MaintenanceComponent = lazy(() => import('../components/maintenance/MaintenanceComponent'));
const HelpComponent = lazy(() => import('../components/help/HelpComponent'));
const RequestTimeoutComponent = lazy(() => import('../components/_error-pages/request-timeout/RequestTimeoutComponent'));
const EmailTriggerComponent = lazy(() => import('../components/email-trigger/EmailTriggerComponent'));
const MarketComponent = lazy(() => import('../components/markets/MarketComponent'));
const StationComponent = lazy(() => import('../components/stations/StationComponent'));
const Booking = lazy(() => import('../components/booking'));
//const DemographicsComponent = lazy(() => import('../components/demographics/DemographicsComponent'));
const ContactComponent = lazy(() => import('../components/contact/ContactComponent'));

class ViewsRouter {
  routes = [
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/`,
      element: <HomeComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/not-found`,
      element: <NotFoundComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/access-denied`,
      element: <AccessDeniedComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/campaigns`,
      element: <Campaigns />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/avails`,
      element: <Avails />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/bookings/:campId/:marketId`,
      element: <Booking />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/markets`,
      element: <Markets />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/programme`,
      element: <Programme />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/data-management/markets`,
      element: <MarketComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/data-management/stations`,
      element: <StationComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/organisations`,
      element: <OrganisationComponent />
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/email-trigger`,
      element: <EmailTriggerComponent />
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/contacts`,
      element: <ContactComponent />
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/internal-server/:message`,
      element: <InternalServerComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/help`,
      element: <HelpComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/maintenance`,
      element: <MaintenanceComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/request-timeout`,
      element: <RequestTimeoutComponent />,
    },
    {
      path: '*',
      element: <Navigate to={`${process.env.REACT_APP_SUBFOLDER}/not-found`} />,
    },

  ];
}

const viewsRouter = new ViewsRouter();
export default viewsRouter;
