/* eslint-disable import/no-cycle */
// import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import {initialFields, tabs} from '../helpers/helpers';
import {useState} from "react";

const useCampaignStore = createWithEqualityFn((set) => ({
  showCampaignForms: false,
  showViewMarkets: false,
  newCampaign: false,
  showViewAvails: false,
  showViewBookings: false,
  setNewCampaign: (data) => set(() => ({ newCampaign: data })),
  setCampaignId: (data) => set(() => ({ campaignId: data })),
  setShowViewMarkets: (data) => set(() => ({ showViewMarkets: data })),
  setShowCampaignForms: (data) => set(() => ({ showCampaignForms: data })),
  campaignForms: tabs,
  setCampaignForms: (data) => set(() => ({ campaignForms: data })),
  activeFormId: '',
  setActiveFormId: (data) => set(() => ({ activeFormId: data })),
  selectedCampaign: null,
  setSelectedCampaign: (data) => set(() => ({ selectedCampaign: data })),
  weeklySpendDates: null,
  setWeeklySpendDates: (data) => set(() => ({ weeklySpendDates: data })),
  markets: null,
  setMarkets: (data) => set(() => ({ markets: data })),
  isFormSaved: false,
  setIsFormSaved: (data) => set(() => ({ isFormSaved: data })),
  changeFormTab: false,
  setChangeFormTab: (data) => set(() => ({ changeFormTab: data })),
  isFormTouched: false,
  setIsFormTouched: (data) => set(() => ({ isFormTouched: data })),
  showConfirmLeaveModal: false,
  setShowConfirmLeaveModal: (data) => set(() => ({ showConfirmLeaveModal: data })),
  dateFormat: "MM/DD/YYYY",
  setDateFormat: (data) => set(() => ({ dateFormat: data })),
  currencyCode: 'USD',
  setCurrencyCode: (data) => set(() => ({ currencyCode: data })),
  setShowViewAvails: (data) => set(() => ({ showViewAvails: data })),
  setShowViewBookings: (data) => set(() => ({ showViewBookings: data })),
  hasMarkets: false,
  setHasMarkets: (data) => set(() => ({ hasMarkets: data })),
  availMarkets: null,
  setAvailsMarketsDataTable: (data) => set(() => ({ availMarkets: data })),
  fields: initialFields,
  setFields: (data) => set(() => ({ fields: data })),
  availFiltered: false,
  setAvailFiltered: (data) => set(() => ({ availFiltered: data })),
  dirtyIds: [],
  setDirtyIds: (data) => set(() => ({ dirtyIds: data })),
  updatedData: [],
  setUpdatedData: (data) => set(() => ({ updatedData: data })),
  permanentData: [],
  setPermanentData: (data) => set(() => ({ permanentData: data })),
  stationName: '',
  setStationName: (data) => set(() => ({ stationName: data })),
  programmeData: '',
  setProgrammeData: (data) => set(() => ({ programmeData: data })),
  switchOption: [],
  setSwitchOption: (data) => set(() => ({ switchOption: data })),
  programmeFilters: [],
  setProgrammeFilters: (data) => set(() => ({ programmeFilters: data })),
  stationFiltered: false,
  setStationFiltered: (data) => set(() => ({ stationFiltered: data })),
  showBookingReadOnly: true,
  setShowBookingReadOnly: (data) => set(() => ({ showBookingReadOnly: data })),
}));

const useStore = (callback) => useCampaignStore(callback, shallow);

export default useStore;
