/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './sidebar.module.css';
import { op2miseMediaPlanningLightIcon, op2miseSmIcon } from '../../assets';
import ProfileComponent from '../profile/profile.component';
import AuthService from '../../core/auth.service';
import { organisationalUserNavigations, organisationalAdminNavigations, superAdminNavigations } from './helpers';
import useStore from '../../components/campaigns/hooks/useCampaignStore';
import clsx from 'clsx';
import "./styles.css"
import useSidenavStore from './hooks/useSidenavStore';
import { chevRightGreyIcon } from '../../assets';
import { USER_TYPE } from '../../enums/userRoles';
const SidebarComponent = (
  {
    sidebarState,
    toggleSidebar,
  }) => {
  const navigate = useNavigate()
  const userIdentity = AuthService.getUserIdentity();
  const routeLinks = userIdentity.roleId === USER_TYPE.superadmin
    ? superAdminNavigations
    : userIdentity.roleId === USER_TYPE.organisationadmin
      ? organisationalAdminNavigations
      : organisationalUserNavigations;
  const { setToPath, setIsNavClicked } = useSidenavStore(state => state)
  const {
    setShowCampaignForms,
    setShowViewMarkets,
  } = useStore((state) => state);

  const location = useLocation();
  const [clickedCollapsibles, setClickedCollapsibles] = useState([]);
  const [logo, setLogo] = useState();
  const [collapsedLogo, setCollapsedLogo] = useState();

  const onClickCollapsible = (title) => {
    if (clickedCollapsibles.includes(title)) {
      setClickedCollapsibles(prevState => ([...prevState.filter((value) => value !== title)]));
    } else {
      setClickedCollapsibles(prevState => ([...prevState, title]));
    }
  };

  const isActiveLink = (subLinks) => {
    return subLinks.map((item) => item.path).includes(location.pathname) ? styles.activeLink : '';
  };

  useEffect(() => {
    setLogo(userIdentity.organisationBase64Logo || op2miseMediaPlanningLightIcon);
    setCollapsedLogo(userIdentity.organisationBase64Logo || op2miseSmIcon);
  }, []);

  const handleNavClick = (path) => {
    if (window.location.pathname !== path) {
      setToPath(path);
      setIsNavClicked(true);
      if (path === '/campaigns') {
        setShowCampaignForms(false);
        setShowViewMarkets(false);
      }
      navigate(path);
    }
  };

  return (
    <div className="position-relative vh-100">
      <div className={styles.sidebarHeader} style={{ padding: sidebarState ? '20px' : '45px' }}>
        <img
          className={sidebarState ? styles.vaiLogoSmall : styles.vaiLogo}
          src={sidebarState ? collapsedLogo : logo}
          alt="op2miseLogo"
        />
      </div>
      <div
        id="scrollbarStyle"
        className={`${styles.sideBarListDiv} scrollbarStyle sideNav-container`}
      >
        {routeLinks.map(({ id, title, collapsible, subLinks, path, icon }) => {
          const element = !collapsible
            ? <nav
                key={id}
                title={sidebarState ? title : null}
                onClick={() => handleNavClick(path)}
                className={clsx({
                  "activeLink": location.pathname === path,
                  'sideNav': true,
                })}
              >
                <span className={styles.iconWrapper}>
                  <img
                    className={location.pathname === path ? styles.whiteIcon : styles.greyIcon}
                    src={icon}
                    alt="nav-icon"
                  />
                </span>
                {!sidebarState && (
                  <span className={`${styles.title} mx-3`}>
                    {title}
                  </span>
                )}
              </nav>
            : <div key={id} className={styles.collapseWrapper}>
                <div
                  className={`${styles.collapseLabel} ${isActiveLink(subLinks)}`}
                  title={sidebarState ? title : null}
                  onClick={() => onClickCollapsible(title)}
                >
                  <span className={styles.colapseIconWrapper}>
                    <img
                      className={location.pathname === path ? styles.whiteIcon : styles.greyIcon}
                      src={icon}
                      alt="nav-icon"
                    />
                  </span>
                  {!sidebarState && (
                    <>
                      <span className={styles.collapseLabelTitle}>{title}</span>
                      <img
                        className={`${styles.chevRightGreyIcon} ${clickedCollapsibles.includes(title) ? styles.rotateIcon : ''}`}
                        src={chevRightGreyIcon} alt=""
                      />
                    </>
                  )}
                </div>

                {clickedCollapsibles.includes(title) && subLinks.map(({ id, subTitle, path, icon }) => (
                    <nav
                      key={id}
                      title={sidebarState ? subTitle : null}
                      onClick={() => handleNavClick(path)}
                      className={`${clsx({ "activeLink": location.pathname === path, 'sideNav': true })} ${styles.collapsibleSubLinks}`}
                    >
                      <span className={styles.iconWrapper}>
                        <img
                          className={location.pathname === path ? styles.whiteIcon : styles.greyIcon}
                          src={icon}
                          alt="nav-icon"
                        />
                      </span>
                      {!sidebarState && (
                        <span className={styles.title}>
                          {subTitle}
                        </span>
                      )}
                    </nav>
                  ))}
              </div>

          return element;
        })}

      </div>

      <div>
        <ProfileComponent
          sidebarState={sidebarState}
          toggleSidebar={toggleSidebar}
        />
      </div>
    </div>
  );
};
SidebarComponent.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};
SidebarComponent.displayName = 'SidebarComponent';
export default SidebarComponent;
